import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const newsPlugin = createPlugin({
  id: 'news',
  routes: {
    root: rootRouteRef,
  },
});

export const NewsPage = newsPlugin.provide(
  createRoutableExtension({
    name: 'NewsPage',
    component: () => import('./components/NewsComponent/NewsPage').then(m => m.NewsPage),
    mountPoint: rootRouteRef,
  }),
);

export const ArticlePage = newsPlugin.provide(
  createRoutableExtension({
    name: 'ArticlePage',
    component: () => import('./components/ArticleComponent/ArticlePage').then(m => m.ArticlePage),
    mountPoint: rootRouteRef,
  }),
);
