export const pageFontFamily = 'Roboto, sans-serif';
export const headerFontFamily = 'Roboto, sans-serif';
export const htmlFontSize = 16;
export const fontWeight: number = 400;
export const marginBottom: number = 8;
export const colors = {
  nintexPurple: '#8439A6',
  orange: '#FF6D00',
  ivory: '#FFF9F4',
  deepPurple: '#3D0456',
  deepNavy: '#000426',
  pink300: '#BE0075',
  pink100: '#ED2891',
  paleOrange: '#FEE9E3',
  navy: '#030A47',
  pink200: '#D60D8C',
  pink050: '#FFD4EB',
  lightOrange: '#FF9F7B',
  blue: '#2A348A',
};
// Default colors for Nintex
// Refer to: https://nintex.sharepoint.com/sites/Marketing/Internalonly%20Content%20Library/Forms/AllItems.aspx?id=%2Fsites%2FMarketing%2FInternalonly%20Content%20Library%2FBrand%20Tools%2FBrand%20Guide%2FNintex%20colors%20including%20Pantone%2Epdf&parent=%2Fsites%2FMarketing%2FInternalonly%20Content%20Library%2FBrand%20Tools%2FBrand%20Guide

export const typography = {
  fontFamily: pageFontFamily,
  htmlFontSize,
  // Patternfly uses root em for these values but numbers are required, so using the pixel equivalent:
  h1: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom,
  },
  h2: {
    fontSize: 20,
    fontWeight,
    marginBottom,
  },
  h3: {
    fontSize: 32,
    fontWeight,
    marginBottom,
  },
  h4: {
    fontSize: 24,
    fontWeight,
    marginBottom,
  },
  h5: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom,
  },
  h6: {
    fontSize: 24,
    fontWeight,
    marginBottom,
  },
};
