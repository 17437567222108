import MyCustomLogoIcon from './nintex-icon.png';
import MyCustomLogoIconWhite from './nintex-icon-white.png';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const LogoIcon = () => {
  const theme = useTheme();

  return (
    <Box sx={{ marginLeft: 2 }}>
      <img
        src={theme.palette.mode === 'dark' ? MyCustomLogoIconWhite : MyCustomLogoIcon}
        alt="Nintex Icon"
      />
    </Box>
  );
};

export default LogoIcon;
