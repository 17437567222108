/* eslint dot-notation: 0 */

import {
  Link,
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  sidebarConfig,
  SidebarSpace,
  useSidebarOpenState,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
  useUserProfile,
} from '@backstage/plugin-user-settings';
import AppsIcon from '@mui/icons-material/Apps';
import ExtensionIcon from '@mui/icons-material/Extension';
import HomeIcon from '@mui/icons-material/Home';
import Book from '@mui/icons-material/Book';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import SchoolIcon from '@mui/icons-material/School';
import SearchIcon from '@mui/icons-material/Search';
import NewsIcon from '@mui/icons-material/Announcement';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import AssistantIcon from '@mui/icons-material/Assistant';
import TrackChangeIcon from '@mui/icons-material/TrackChanges';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import History from '@mui/icons-material/History';
import { PropsWithChildren, useEffect } from 'react';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import { ConfigCatFeatureFlag } from '@internal/plugin-config-cat';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import RocketIcon from '@mui/icons-material/Rocket';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { PagerDutyIcon } from '@pagerduty/backstage-plugin';
import KeyIcon from '@mui/icons-material/Key';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Science from '@mui/icons-material/Science';
import Box from '@mui/material/Box';

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <Box
      sx={{
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Link to="/" underline="none" aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </Box>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const userProfile = useUserProfile();

  useEffect(() => {
    const canSendGA4Properties =
      window &&
      window['gtag'] &&
      window['dataLayer'] &&
      Array.isArray(window['dataLayer']) &&
      !userProfile.loading &&
      Boolean(userProfile?.displayName?.trim());

    if (canSendGA4Properties) {
      const { profile, backstageIdentity } = userProfile;

      const gtagProperties = {
        displayName: profile.displayName,
        email: profile.email,
        userEntityRef: backstageIdentity?.userEntityRef,
      };

      if (Array.isArray(backstageIdentity?.ownershipEntityRefs)) {
        const useOwnershipEntityRefsIndex =
          backstageIdentity && backstageIdentity?.ownershipEntityRefs?.length > 1;
        const ownershipEntityRefsKey = `ownershipEntityRefs`;

        backstageIdentity?.ownershipEntityRefs.forEach((x, i) => {
          const propKey = useOwnershipEntityRefsIndex
            ? `${ownershipEntityRefsKey}_${i}`
            : ownershipEntityRefsKey;

          gtagProperties[propKey] = x;
        });
      }

      // 'gtag' method called with bracket notation to make TS happy
      window['gtag']('set', 'user_properties', gtagProperties);
    }
  }, [userProfile]);

  return (
    <SidebarPage>
      <Sidebar
        submenuOptions={{
          drawerWidthOpen: 250,
        }}
      >
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}

          <SidebarItem icon={HomeIcon as any} to="/" text="Home" />
          <SidebarItem icon={AppsIcon as any} to="catalog" text="Catalog" />
          <SidebarItem
            icon={Book as any}
            text="Wiki"
            onClick={event => {
              event.preventDefault();
              window.open('https://dev.azure.com/nintex/Nintex/_wiki/wikis/', '_blank');
            }}
          />
          <SidebarItem icon={ExtensionIcon as any} to="api-docs" text="APIs" />
          <SidebarItem icon={LibraryBooks as any} to="docs" text="Docs" />
          <SidebarItem icon={CreateComponentIcon as any} to="create" text="Create..." />
          <ConfigCatFeatureFlag flag="automaton-enabled">
            <SidebarItem icon={PrecisionManufacturingIcon as any} to="automaton" text="Automaton" />
          </ConfigCatFeatureFlag>
          <ConfigCatFeatureFlag flag="nintester-enabled">
            <SidebarItem icon={Science as any} to="nintester" text="NinTester" />
          </ConfigCatFeatureFlag>

          {/* End global nav */}
          <SidebarDivider />
          <SidebarItem icon={NewsIcon as any} to="news" text="News" />
          <SidebarItem icon={SortByAlphaIcon as any} to="/glossary#" text="Glossary" />
          <SidebarItem icon={SchoolIcon as any} to="learning-paths" text="Learning Paths" />
          <SidebarItem icon={MapIcon as any} to="tech-radar" text="Tech Radar" />

          <SidebarDivider />

          <SidebarItem icon={AssistantIcon as any} to="chat" text="Platty (Beta)" />

          <ConfigCatFeatureFlag flag="licenses-enabled">
            <SidebarItem icon={KeyIcon as any} to="licenses" text="Licenses" />
          </ConfigCatFeatureFlag>

          <SidebarItem icon={RocketIcon as any} text="NSP">
            <SidebarSubmenu title="Nintex Shared Platform">
              <SidebarSubmenuItem icon={History as any} to="trebuchet" title="Track Changes" />
              <SidebarSubmenuItem
                icon={RocketLaunchIcon as any}
                to="nsp-launchpad/start"
                title="Launchpad"
              />

              <SidebarSubmenuItem
                icon={TrackChangeIcon as any}
                to="nsp/onboarding-tracker"
                title="Services Onboard"
              />

              <ConfigCatFeatureFlag flag="nsp-status-enabled">
                <SidebarSubmenuItem icon={MonitorHeartIcon as any} to="nsp/status" title="Status" />
              </ConfigCatFeatureFlag>

              <ConfigCatFeatureFlag flag="nsp-cost-enabled">
                <SidebarSubmenuItem icon={AttachMoneyIcon as any} to="nsp/cost" title="Cost" />
              </ConfigCatFeatureFlag>

              <ConfigCatFeatureFlag flag="pagerduty-enabled">
                <SidebarSubmenuItem
                  icon={PagerDutyIcon as any}
                  to="nsp/pagerduty"
                  title="PagerDuty"
                />
              </ConfigCatFeatureFlag>
              <ConfigCatFeatureFlag flag="maintenance-comms-enabled">
                <SidebarSubmenuItem
                  icon={NotificationsActiveIcon as any}
                  to="maintenance-comms"
                  title="Maintenance Comms"
                />
              </ConfigCatFeatureFlag>
            </SidebarSubmenu>
          </SidebarItem>
        </SidebarGroup>

        <SidebarSpace />

        {/* settings nav */}
        <SidebarDivider />
        <SidebarGroup label="Settings" icon={<UserSettingsSignInAvatar />} to="/settings">
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
