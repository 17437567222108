import { Content, Header, Page } from '@backstage/core-components';
import { HomePageStarredEntities } from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { HeaderLabel } from '@backstage/core-components';
import { VERSION } from '../../constants/version';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { SupportRequests } from './SupportRequests';
import { QuickAccess } from './QuickAccess';

export const HomePage = () => {
  const { profile } = useUserProfile();
  const displayName = profile?.displayName || '';
  const firstName = displayName.split(/(\s+)/)[0];

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title={`Welcome back to NDP, ${firstName}!`}>
          <HeaderLabel label="Owned by" value="Platform Team" url="mailto:platform@nintex.com" />
          <HeaderLabel label="Version" value={VERSION} />
        </Header>
        <Content>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              <HomePageSearchBar placeholder="Search" />
            </Box>

            <SupportRequests />

            <Grid container spacing={0}>
              <Grid item xs={12} md={7} style={{ paddingRight: '16px' }}>
                <QuickAccess />
              </Grid>
              <Grid item xs={12} md={5} style={{ paddingLeft: '16px' }}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
          </Box>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
