import { FlatRoutes } from '@backstage/core-app-api';
import { ErrorPage } from '@backstage/core-components';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogIndexPage, CatalogEntityPage } from '@backstage/plugin-catalog';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { CatalogImportPage } from '@backstage/plugin-catalog-import';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { PagerDutyPage } from '@pagerduty/backstage-plugin';
import { SelectFieldFromApiExtension } from '@roadiehq/plugin-scaffolder-frontend-module-http-request-field';

import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  DefaultTechDocsHome,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import {
  ReportIssue,
  ExpandableNavigation,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { ConfigCatFeatureFlag } from '@internal/plugin-config-cat';
import { NspLaunchpadPage, NspLaunchpadStatusPage } from '@internal/plugin-nsp-launchpad';
import { ChangeDetailsPage, ChangeListPage } from '@internal/plugin-trebuchet';
import { Route } from 'react-router-dom';
import { entityPage } from './components/catalog/EntityPage';
import { ChatPage } from './components/chat/ChatPage';
import { GlossaryPage } from './components/glossary/GlossaryPage';
import { HomePage } from './components/home/HomePage';
import { LearningPaths } from './components/learning-paths/LearningPaths';
import { NewsPage, ArticlePage } from '@internal/backstage-plugin-news';
import { DomainPickerFieldExtension } from './components/scaffolder/DomainPicker';
import { PrefixedTextFieldExtension } from './components/scaffolder/PrefixedTextField';
import { searchPage } from './components/search/SearchPage';
import { SavingsPage } from './components/savings-tracker/SavingsTracker';
import { NspStatusPage } from '@internal/backstage-plugin-nsp-status';
import { InfraWalletPage } from '@electrolux-oss/plugin-infrawallet';
import { LicensePage } from '@internal/backstage-plugin-license';
import { CustomRepoPickerFieldExtension } from './components/scaffolder/CustomRepoPicker';
import { MaintenanceCommsPage } from '@internal/backstage-plugin-maintenance-comms';
import { AutomatonPage, AutomatonFormFieldExtensions } from '@internal/backstage-plugin-automaton';
import {
  CatalogUserFieldExtension,
  CatalogMultiUserFieldExtension,
} from '@internal/backstage-plugin-automaton-module-catalog-react';
import { OnboardingTrackerPage } from '@internal/backstage-plugin-onboarding-tracker';
import { NinTesterPage } from '@internal/backstage-plugin-nintester';
import {
  ConfigCatProductAndPermissionFieldExtension,
  ConfigCatProductFieldExtension,
} from '@internal/backstage-plugin-automaton-module-config-cat-admin-react';
import {
  SlackUserFieldExtension,
  SlackChannelFieldExtension,
} from '@internal/backstage-plugin-automaton-module-slack-react';
import { AzureGroupsFieldExtension } from '@internal/backstage-plugin-automaton-module-azure-react';
import { TechRadarEntryFieldExtension } from '@internal/backstage-plugin-automaton-module-techradar-react';

export const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>

    <Route path="/news" element={<NewsPage />} />
    <Route path="/news/articles/*" element={<ArticlePage />} />

    <Route path="/glossary" element={<GlossaryPage />} />

    <Route
      path="/catalog"
      element={<CatalogIndexPage initiallySelectedFilter="all" initialKind="domain" />}
    />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <ReportIssue />
        <ExpandableNavigation />
        <TextSize />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity => entity?.metadata?.tags?.includes('recommended') ?? false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <DomainPickerFieldExtension />
        <PrefixedTextFieldExtension />
        <SelectFieldFromApiExtension />
        <CustomRepoPickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/tech-radar" element={<TechRadarPage width={1500} height={800} />} />
    <Route path="/chat" element={<ChatPage />} />
    <Route path="/trebuchet" element={<ChangeListPage />} />
    <Route path="/trebuchet/:manifestId" element={<ChangeDetailsPage />} />

    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/learning-paths" element={<LearningPaths />} />
    <Route path="/nsp-launchpad/start" element={<NspLaunchpadPage />} />
    <Route path="/nsp-launchpad/status/:workflowId" element={<NspLaunchpadStatusPage />} />

    <Route
      path="/savings-tracker"
      element={
        <ConfigCatFeatureFlag
          flag="savings-tracker-enabled"
          disabledComponent={<ErrorPage status="404" statusMessage="PAGE NOT FOUND" />}
        >
          <SavingsPage />
        </ConfigCatFeatureFlag>
      }
    />

    <Route
      path="/nsp/status"
      element={
        <ConfigCatFeatureFlag
          flag="nsp-status-enabled"
          disabledComponent={<ErrorPage status="404" statusMessage="PAGE NOT FOUND" />}
        >
          <NspStatusPage />
        </ConfigCatFeatureFlag>
      }
    />

    <Route
      path="/nsp/cost"
      element={
        <ConfigCatFeatureFlag
          flag="nsp-cost-enabled"
          disabledComponent={<ErrorPage status="404" statusMessage="PAGE NOT FOUND" />}
        >
          <InfraWalletPage title="NSP Cost" subTitle="Visualize the running cost of NSP" />
        </ConfigCatFeatureFlag>
      }
    />

    <Route
      path="/nsp/pagerduty"
      element={
        <ConfigCatFeatureFlag
          flag="pagerduty-enabled"
          disabledComponent={<ErrorPage status="404" statusMessage="PAGE NOT FOUND" />}
        >
          <PagerDutyPage />
        </ConfigCatFeatureFlag>
      }
    />

    <Route path="/nsp/onboarding-tracker" element={<OnboardingTrackerPage />} />

    <Route
      path="/licenses"
      element={
        <ConfigCatFeatureFlag
          flag="licenses-enabled"
          disabledComponent={<ErrorPage status="404" statusMessage="PAGE NOT FOUND" />}
        >
          <LicensePage />
        </ConfigCatFeatureFlag>
      }
    />

    <Route
      path="/maintenance-comms"
      element={
        <ConfigCatFeatureFlag
          flag="maintenance-comms-enabled"
          disabledComponent={<ErrorPage status="404" statusMessage="PAGE NOT FOUND" />}
        >
          <MaintenanceCommsPage />
        </ConfigCatFeatureFlag>
      }
    />

    <Route path="/nintester" element={<NinTesterPage />} />

    <Route
      path="/nintester"
      element={
        <ConfigCatFeatureFlag
          flag="nintester-enabled"
          disabledComponent={<ErrorPage status="404" statusMessage="PAGE NOT FOUND" />}
        >
          <NinTesterPage />
        </ConfigCatFeatureFlag>
      }
    />

    <Route
      path="/automaton"
      element={
        <ConfigCatFeatureFlag
          flag="automaton-enabled"
          disabledComponent={<ErrorPage status="404" statusMessage="PAGE NOT FOUND" />}
        >
          <AutomatonPage />
        </ConfigCatFeatureFlag>
      }
    >
      <AutomatonFormFieldExtensions>
        <CatalogUserFieldExtension />
        <CatalogMultiUserFieldExtension />
        <ConfigCatProductAndPermissionFieldExtension />
        <ConfigCatProductFieldExtension />
        <SlackUserFieldExtension />
        <SlackChannelFieldExtension />
        <AzureGroupsFieldExtension />
        <TechRadarEntryFieldExtension />
      </AutomatonFormFieldExtensions>
    </Route>
  </FlatRoutes>
);
