import { useEntity } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { Progress, ErrorPanel, MarkdownContent, InfoCard } from '@backstage/core-components';
import { useFetchMarkdownFromGHRepo } from '@internal/backstage-plugin-frontend-common-react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';

export const GithubReadmeCard = () => {
  const { entity } = useEntity();
  const fetchMarkdownContent = useFetchMarkdownFromGHRepo();
  const theme = useTheme();

  const githubSlug = entity.metadata.annotations?.['github.com/project-slug'];
  const defaultBranch = entity.metadata.annotations?.['github.com/default-branch'] || 'main';
  const readmeFile = entity.metadata.annotations?.['github.com/readme-file'] || 'README.md';
  const url = `https://github.com/${githubSlug}`;

  const { value, loading, error } = useAsync(async () => {
    if (!githubSlug) {
      throw new Error(
        'GitHub project slug annotation not found, add a github.com/project-slug annotation to the catalog entity',
      );
    }

    const markdown = await fetchMarkdownContent(url, defaultBranch, readmeFile, false);

    if (!markdown) {
      throw new Error(`No ${readmeFile} found at ${url} on ${defaultBranch}`);
    }

    return markdown as string;
  }, [githubSlug]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <ErrorPanel title="Failed to load README" error={error} />;
  }

  return (
    <InfoCard
      title="Readme"
      deepLink={{
        link: `${url}/blob/${defaultBranch}/${readmeFile}`,
        title: 'Readme',
      }}
    >
      <Box sx={{ padding: theme.spacing(1) }}>
        <MarkdownContent content={value || 'No README found'} />
      </Box>
    </InfoCard>
  );
};
