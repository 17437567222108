import { FormFieldComponent } from '@internal/backstage-plugin-automaton';
import {
  automatonPlugin,
  createAutomatonFormFieldExtension,
} from '@internal/backstage-plugin-automaton';
import { fetchApiRef, useApi, discoveryApiRef } from '@backstage/core-plugin-api';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { SlackChannel } from '@internal/backstage-plugin-slack-common';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import Alert from '@mui/material/Alert';

const SlackChannelInput: FormFieldComponent = ({ id }) => {
  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const [channels, setChannels] = useState<SlackChannel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [value, setValue] = useState<string>();

  const getNextChannelCursor = useCallback(
    async (types: string, cursor?: string) => {
      const baseUrl = await discoveryApi.getBaseUrl('slack');

      const searchParams = new URLSearchParams();
      searchParams.append('types', types);

      if (cursor) {
        searchParams.append('cursor', cursor);
      }

      const response = await fetchApi.fetch(`${baseUrl}/channels?${searchParams.toString()}`);
      const { channels, nextCursor } = await response.json();

      setChannels(currentChannels => {
        return [...currentChannels, ...channels];
      });

      if (nextCursor) {
        await getNextChannelCursor(types, nextCursor);
      }
    },
    [fetchApi, discoveryApi],
  );

  const getUsers = useCallback(async () => {
    setLoading(true);

    await getNextChannelCursor('public_channel');
    await getNextChannelCursor('private_channel');

    setLoading(false);
  }, [getNextChannelCursor]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onChange = (_event: SyntheticEvent, value: SlackChannel | null) => {
    if (!value) {
      setValue(undefined);
      return;
    }

    setValue(value.id);
  };

  return (
    <>
      <Autocomplete
        loading={loading}
        options={channels}
        getOptionLabel={option => option.name}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            label="Select a slack channel"
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              },
            }}
          />
        )}
      />
      <Input type="hidden" name={id} value={value} />
      <Alert severity="info" sx={{ mt: 2 }}>
        Don't see the channel you are looking for? NDP can only access public channels and private
        channels it has been invited to. Please add "@NDP Slackbot" to your channel and try again.
      </Alert>
    </>
  );
};

export const SlackChannelFieldExtension = automatonPlugin.provide(
  createAutomatonFormFieldExtension({
    name: 'slack-channel',
    component: SlackChannelInput,
  }),
);
