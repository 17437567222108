import { discoveryApiRef, fetchApiRef, useApi } from '@backstage/frontend-plugin-api';
import {
  Environment,
  Invitation,
  Permission,
  Product,
  User,
} from '@internal/backstage-plugin-config-cat-admin-common';

export const useConfigCatAdmin = () => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  function getBackendUrl(): Promise<string> {
    return discoveryApi.getBaseUrl('config-cat-admin');
  }

  return {
    async listProducts(): Promise<Product[]> {
      return backendRequest('products');
    },

    async listProductMembers(productId: string): Promise<User[]> {
      return backendRequest(`/products/${productId}/members`);
    },
    async listProductPermissionGroups(productId: string): Promise<Permission[]> {
      return backendRequest(`/products/${productId}/permissions`);
    },

    async listProductEnvironments(productId: string): Promise<Environment[]> {
      return backendRequest(`/products/${productId}/environments`);
    },

    async listProductInvitations(productId: string): Promise<Invitation[]> {
      return backendRequest(`/products/${productId}/invites`);
    },
  };

  async function backendRequest<T>(path: string) {
    const backendUrl = await getBackendUrl();
    const response = await fetchApi.fetch(`${backendUrl}/${path}`);

    if (!response.ok) {
      throw new Error(`Request to ${path} failed with status ${response.status}`);
    }
    return response.json() as Promise<T>;
  }
};
