import {
  AnalyticsApi,
  AnalyticsEvent,
  ConfigApi,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { PlatformAnalyticsClient } from './PlatformAnalyticsClient';
import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';

// This class is responsible for aggregating all the analytics clients and forwarding events to them.
export class AggregateAnalyticsClient implements AnalyticsApi {
  private readonly clients: AnalyticsApi[];

  static fromConfig(
    configApi: ConfigApi,
    identityApi: IdentityApi,
    fetchApi: FetchApi,
    discoveryApi: DiscoveryApi,
  ): AggregateAnalyticsClient {
    const clients: AnalyticsApi[] = [
      PlatformAnalyticsClient.fromConfig(identityApi, fetchApi, discoveryApi),
      GoogleAnalytics4.fromConfig(configApi, { identityApi }),
    ];

    return new AggregateAnalyticsClient(clients);
  }

  private constructor(clients: AnalyticsApi[]) {
    this.clients = clients;
  }

  async captureEvent(event: AnalyticsEvent) {
    await Promise.all(this.clients.map(client => client.captureEvent(event)));
  }
}
