import { createUnifiedTheme, themes } from '@backstage/theme';
import { components } from './componentOverrides';
import { pageFontFamily, colors } from './consts';
import { pageTheme } from './pageTheme';

export const nintexDarkTheme = createUnifiedTheme({
  fontFamily: pageFontFamily,
  palette: {
    ...themes.dark.getTheme('v5')?.palette,
    primary: { main: colors.pink200 },
    secondary: { main: colors.nintexPurple },
    navigation: {
      ...themes.dark.getTheme('v5')!.palette.navigation,
      indicator: colors.pink200,
    },
  },
  defaultPageTheme: 'home',
  pageTheme,
  components,
});
