import { FormFieldComponent } from '@internal/backstage-plugin-automaton';
import {
  automatonPlugin,
  createAutomatonFormFieldExtension,
} from '@internal/backstage-plugin-automaton';
import { fetchApiRef, useApi, discoveryApiRef } from '@backstage/core-plugin-api';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { SlackUser } from '@internal/backstage-plugin-slack-common';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';

const SlackUserInput: FormFieldComponent = ({ id }) => {
  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const [users, setUsers] = useState<SlackUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [value, setValue] = useState<string>();

  const getNextUserCursor = useCallback(
    async (cursor?: string) => {
      const baseUrl = await discoveryApi.getBaseUrl('slack');

      const searchParams = new URLSearchParams();

      if (cursor) {
        searchParams.append('cursor', cursor);
      }

      const response = await fetchApi.fetch(`${baseUrl}/users?${searchParams.toString()}`);
      const { users, nextCursor } = await response.json();

      setUsers(currentUsers => {
        return [...currentUsers, ...users];
      });

      if (!nextCursor) {
        return;
      }
      await getNextUserCursor(nextCursor);
    },
    [fetchApi, discoveryApi],
  );

  const getUsers = useCallback(async () => {
    setLoading(true);
    await getNextUserCursor();

    setLoading(false);
  }, [getNextUserCursor]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onChange = (_event: SyntheticEvent, value: SlackUser | null) => {
    if (!value) {
      setValue(undefined);
      return;
    }

    setValue(value.id);
  };

  return (
    <>
      <Autocomplete
        loading={loading}
        options={users}
        getOptionLabel={option => `${option.realName} (${option.email})`}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            label="Select a slack user"
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              },
            }}
          />
        )}
      />
      <Input type="hidden" name={id} value={value} />
    </>
  );
};

export const SlackUserFieldExtension = automatonPlugin.provide(
  createAutomatonFormFieldExtension({
    name: 'slack-user',
    component: SlackUserInput,
  }),
);
