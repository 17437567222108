import React, { useState, useMemo } from 'react';
import { Content, Header, ItemCardHeader, Page, ItemCardGrid } from '@backstage/core-components';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import CardMedia from '@mui/material/CardMedia';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import { useFetchJsonFromGHRepo } from '@internal/backstage-plugin-frontend-common-react';
import { useApi, discoveryApiRef, fetchApiRef, errorApiRef } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

const MAX_DESCRIPTION_LENGTH = 380; // Limiting the size of the description to 380 chars so that the cards size doesn't go overboard
const LEARNING_PATH_API_URL = '/learningpaths';

type Path = {
  label: string;
  url: string;
  description?: string;
  hours?: number;
  minutes?: number;
  paths?: number;
  tags?: string;
};

type SearchAndFilterProps = {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  allTags: string[];
};

const truncateDescription = (
  description?: string,
  maxLength: number = MAX_DESCRIPTION_LENGTH,
): string => {
  if (!description) {
    return '';
  }
  if (description.length <= maxLength) {
    return description;
  }
  return description.slice(0, maxLength);
};

const learningPathLengthInfo = (path: Path) => {
  const hoursText = path.hours === 1 ? 'hour' : 'hours';
  const minutesText = path.minutes === 1 ? 'minute' : 'minutes';

  const hours = path.hours ? `${path.hours} ${hoursText}` : '';
  const minutes = path.minutes ? `${path.minutes} ${minutesText}` : '';

  // if no hours or minutes
  if (!hours && !minutes) {
    return `${path.paths} learning paths`;
  }

  return `${hours} ${minutes} | ${path.paths} learning paths`;
};

const learningPathTags = (input: string | undefined): string[] => {
  if (!input) {
    return [];
  }
  return input.split(',').map(item => item.trim());
};

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  searchQuery,
  setSearchQuery,
  selectedTags,
  setSelectedTags,
  allTags,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <TextField
        id="searchQuery"
        label="Search"
        variant="outlined"
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        style={{ marginRight: '20px' }}
      />
      <FormControl variant="outlined" style={{ width: '200px' }}>
        <InputLabel htmlFor="filterByTags">Filter by Tags</InputLabel>
        <Select
          multiple
          value={selectedTags}
          onChange={e => setSelectedTags(e.target.value as string[])}
          label="Filter by Tags"
          inputProps={{
            id: 'filterByTags',
          }}
          renderValue={selected => (selected as string[]).join(', ')}
          MenuProps={{
            MenuListProps: {
              sx: {
                display: 'flex',
                flexDirection: 'column',
              },
            },
          }}
        >
          {allTags.map(tag => (
            <MenuItem key={tag} value={tag}>
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const LearningPathCard = ({ path }: { path: Path }) => {
  return (
    <Card
      key={path.label}
      sx={{
        minWidth: 275,
        transition: '0.3s',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0 6px 10px 3px rgba(0, 0, 0, .3)',
        },
      }}
    >
      <CardMedia>
        <ItemCardHeader title={path.label} subtitle={learningPathLengthInfo(path)} />
      </CardMedia>
      <CardContent>
        {learningPathTags(path.tags).map(tag => (
          <Chip key={tag} label={tag} />
        ))}
        <Typography variant="body2">{truncateDescription(path.description)}</Typography>
      </CardContent>
      <CardActions>
        <Link href={path.url} target="_blank" rel="noreferrer">
          <Button size="small">Learn More</Button>
        </Link>
      </CardActions>
    </Card>
  );
};

const LearningPathCards = () => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const errorApi = useApi(errorApiRef);
  const fetchJsonContent = useFetchJsonFromGHRepo();

  const { value: configData, error: configError } = useAsync(async () => {
    const url = await discoveryApi.getBaseUrl('platform');
    const response = await fetchApi.fetch(url + LEARNING_PATH_API_URL);
    return response.json();
  });

  const { value: githubData } = useAsync(async () => {
    try {
      if (!configData) {
        return [];
      }

      const data = await fetchJsonContent<Path[]>(
        configData.gitRepoUrl,
        configData.branch,
        configData.filePath,
      );

      return data;
    } catch (error) {
      errorApi.post({
        ...(error as Error),
        message: 'Error fetching glossary config data',
      });
      return [];
    }
  }, [configData]);

  const allTags: string[] = useMemo(() => {
    if (!githubData) {
      return [];
    }

    return Array.from(new Set(githubData?.flatMap(p => learningPathTags(p.tags))));
  }, [githubData]);

  const isLoading = !configData || !githubData;

  if (configError) {
    return <Alert severity="error">Could not fetch config: {configError.message}</Alert>;
  }

  const filteredData = githubData
    ? githubData.filter((p: Path) => {
        const tagsArray = learningPathTags(p.tags);
        if (selectedTags.length > 0 && !selectedTags.every(tag => tagsArray.includes(tag))) {
          return false;
        }
        if (
          searchQuery &&
          !p.label.toLowerCase().includes(searchQuery.toLowerCase()) &&
          (!p.description || !p.description.toLowerCase().includes(searchQuery.toLowerCase())) &&
          !tagsArray.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          return false;
        }
        return true;
      })
    : [];

  return (
    <div>
      <SearchAndFilter
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        allTags={allTags}
      />
      {isLoading && !configError ? (
        <CircularProgress />
      ) : (
        <ItemCardGrid>
          {filteredData.map(path => (
            <LearningPathCard key={path.label} path={path} />
          ))}
        </ItemCardGrid>
      )}
    </div>
  );
};

export const LearningPaths = () => {
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="Learning Paths" />
        <Content>
          <LearningPathCards />
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
