import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@mui/material/Input';
import {
  automatonPlugin,
  createAutomatonFormFieldExtension,
  FormFieldComponent,
} from '@internal/backstage-plugin-automaton';
import { SyntheticEvent, useState } from 'react';
import { Permission, Product } from '@internal/backstage-plugin-config-cat-admin-common';
import { useConfigCatAdmin } from '../../useConfigCatAdmin';
import { useAsync } from 'react-use';

const ConfigCatProductAndPermissionField: FormFieldComponent = ({ id }) => {
  const { listProducts, listProductPermissionGroups } = useConfigCatAdmin();
  const [value, setValue] = useState<string>();
  const [product, setProduct] = useState<Product>();

  const { value: products } = useAsync(async () => {
    return await listProducts();
  });

  const { value: permissions } = useAsync(async () => {
    if (!product) {
      return [];
    }

    let permissions: Permission[] = [];
    try {
      permissions = await listProductPermissionGroups(product?.productId);
    } catch (error) {
      setValue(undefined);
      return [];
    }

    return permissions;
  }, [product]);

  const onProductChange = (_event: SyntheticEvent, value: Product | null) => {
    if (!value) {
      setProduct(undefined);
      return;
    }
    setProduct(value);
  };

  const onPermissionGroupChange = (_event: SyntheticEvent, value: Permission | null) => {
    if (!value) {
      return;
    }

    setValue(
      JSON.stringify({
        productId: product?.productId,
        permissionGroupId: value.permissionGroupId,
      }),
    );
  };

  return (
    <>
      <Autocomplete
        sx={{ marginBottom: '1rem' }}
        options={products || []}
        getOptionLabel={option => `${option.name} (${option.productId})`}
        onChange={onProductChange}
        renderInput={params => <TextField {...params} label="Select a product" />}
      />

      <Autocomplete
        options={permissions || []}
        getOptionLabel={option => `${option.name} (${option.permissionGroupId})`}
        onChange={onPermissionGroupChange}
        disabled={product === null}
        renderInput={params => (
          <TextField {...params} label="Select an available permission group" />
        )}
      />
      <Input type="hidden" name={id} value={value} />
    </>
  );
};

export const ConfigCatProductAndPermissionFieldExtension = automatonPlugin.provide(
  createAutomatonFormFieldExtension({
    name: 'config-cat-product-permission',
    component: ConfigCatProductAndPermissionField,
  }),
);
