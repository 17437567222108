import { createUnifiedTheme, themes } from '@backstage/theme';
import { components } from './componentOverrides';
import { pageFontFamily, colors } from './consts';
import { pageTheme } from './pageTheme';

export const nintexLightTheme = createUnifiedTheme({
  fontFamily: pageFontFamily,
  palette: {
    ...themes.light.getTheme('v5')?.palette,
    primary: { main: colors.pink200 },
    secondary: { main: colors.nintexPurple },
    navigation: {
      ...themes.dark.getTheme('v5')?.palette.navigation,
      indicator: colors.pink200,
      background: themes.light.getTheme('v5')!.palette.background.paper,
      color: themes.light.getTheme('v5')!.palette.text.secondary,
      selectedColor: themes.light.getTheme('v5')!.palette.text.primary,
      navItem: {
        hoverBackground: themes.light.getTheme('v5')!.palette.action.hover,
      },
      submenu: {
        background: themes.light.getTheme('v5')!.palette.background.paper,
      },
    },
  },
  defaultPageTheme: 'home',
  pageTheme,
  components,
});
