import { FormFieldComponent } from '@internal/backstage-plugin-automaton';
import {
  automatonPlugin,
  createAutomatonFormFieldExtension,
} from '@internal/backstage-plugin-automaton';
import { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { SyntheticEvent } from 'react';
import Input from '@mui/material/Input';
import { useFetchJsonFromGHRepo } from '@internal/backstage-plugin-frontend-common-react';
import { FilterOptionsState } from '@mui/material';

// Only use title because its all we care about for this component
type TechRadarElement = {
  title: string;
  inputValue?: string;
};

const REPO_URL = 'https://github.com/NintexGlobal/platform-reference';
const BRANCH = 'main';
const PATH = 'ndp/components/techradar/radar.json';

const TechRadarEntry: FormFieldComponent = ({ id }) => {
  const [elements, setElements] = useState<TechRadarElement[]>([]);
  const [value, setValue] = useState<string>();

  const fetchJsonContent = useFetchJsonFromGHRepo();

  const filter = createFilterOptions<TechRadarElement>();

  const fetchElements = useCallback(async () => {
    const data = await fetchJsonContent<TechRadarElement[]>(REPO_URL, BRANCH, PATH);

    setElements(data);
  }, [fetchJsonContent]);

  useEffect(() => {
    fetchElements();
  }, [fetchElements]);

  const onChange = (_event: SyntheticEvent, newValue: string | TechRadarElement | null) => {
    if (typeof newValue === 'string') {
      setValue(newValue);
      return;
    }

    if (!newValue) {
      setValue(undefined);
      return;
    }

    if (newValue.inputValue) {
      setValue(newValue.inputValue);
      return;
    }

    setValue(newValue.title);
  };

  const filterOptions = (
    options: TechRadarElement[],
    params: FilterOptionsState<TechRadarElement>,
  ) => {
    const filtered = filter(options, params);

    const { inputValue } = params;

    // Suggest the creation of a new value
    const isExisting = options.some(option => inputValue === option.title);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        title: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = (option: string | TechRadarElement) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.title;
  };

  return (
    <>
      <Autocomplete
        options={elements}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.title}
            </li>
          );
        }}
        freeSolo
        selectOnFocus
        clearOnBlur
        onChange={onChange}
        renderInput={params => <TextField {...params} label="Title" />}
      />
      <Input type="hidden" name={id} value={value} />
    </>
  );
};

export const TechRadarEntryFieldExtension = automatonPlugin.provide(
  createAutomatonFormFieldExtension({
    name: 'tech-radar-entry',
    component: TechRadarEntry,
  }),
);
