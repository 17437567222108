import { FormFieldComponent } from '@internal/backstage-plugin-automaton';
import {
  automatonPlugin,
  createAutomatonFormFieldExtension,
} from '@internal/backstage-plugin-automaton';
import { fetchApiRef, useApi, discoveryApiRef } from '@backstage/core-plugin-api';
import { SyntheticEvent, useState, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import { useAsync } from 'react-use';
import { AzureGroup } from '@internal/backstage-plugin-azure-common';

const AzureGroupsInput: FormFieldComponent = ({ id }) => {
  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const [value, setValue] = useState<string>();
  const [dir, setDir] = useState<string>();

  const { value: dirs } = useAsync(async () => {
    const backendUrl = await discoveryApi.getBaseUrl('azure');
    const response = await fetchApi.fetch(`${backendUrl}/directories`);

    return response.json() as Promise<string[]>;
  });

  const { value: groups } = useAsync(async () => {
    if (!dir) {
      return [];
    }
    const backendUrl = await discoveryApi.getBaseUrl('azure');
    const response = await fetchApi.fetch(`${backendUrl}/${dir}/groups`);

    if (!response.ok) {
      throw new Error(`Request to ${dir} failed with status ${response.status}`);
    }
    return response.json() as Promise<AzureGroup[]>;
  }, [dir]);

  const onDirChange = (_event: SyntheticEvent, value: string | null) => {
    if (!value) {
      setValue(undefined);
      return;
    }

    setDir(value);
  };

  const renderGroupSelection = useCallback(() => {
    const onGroupChange = (_event: SyntheticEvent, value: AzureGroup[] | null) => {
      if (!value) {
        setValue(undefined);
        return;
      }

      const values = (value as AzureGroup[]).map<{
        groupId: string;
        groupName: string;
        directoryId: string;
      }>(group => ({
        groupId: group.id,
        groupName: group.displayName,
        directoryId: dir!,
      }));

      setValue(JSON.stringify(values));
    };

    if (!dir) {
      return (
        <TextField label="First select an Azure directory" disabled sx={{ marginBottom: '1rem' }} />
      );
    }

    if (groups?.length === 0) {
      return <TextField label="Loading..." disabled sx={{ marginBottom: '1rem' }} />;
    }

    return (
      <Autocomplete
        multiple
        sx={{ marginBottom: '1rem' }}
        options={groups || []}
        getOptionLabel={option => `${option.displayName}`}
        onChange={onGroupChange}
        renderInput={params => <TextField {...params} label="Select Azure Groups" />}
      />
    );
  }, [dir, groups]);

  return (
    <>
      <Autocomplete
        sx={{ marginBottom: '1rem' }}
        options={dirs || []}
        onChange={onDirChange}
        renderInput={params => <TextField {...params} label="Select an Azure Directory" />}
      />

      {renderGroupSelection()}

      <Input type="hidden" name={id} value={value} />
    </>
  );
};

export const AzureGroupsFieldExtension = automatonPlugin.provide(
  createAutomatonFormFieldExtension({
    name: 'azure-groups',
    component: AzureGroupsInput,
  }),
);
