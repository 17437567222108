import { PageTheme, genPageTheme, shapes } from '@backstage/theme';
import { colors } from './consts';

export const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme({
    colors: [colors.deepPurple, colors.pink200, colors.orange],
    shape: shapes.wave,
  }),
  app: genPageTheme({
    colors: [colors.deepPurple, colors.pink200, colors.orange],
    shape: shapes.wave,
  }),
  apis: genPageTheme({
    colors: [colors.deepPurple, colors.pink200, colors.orange],
    shape: shapes.wave,
  }),
  documentation: genPageTheme({
    colors: [colors.deepPurple, colors.pink200, colors.orange],
    shape: shapes.wave,
  }),
  other: genPageTheme({
    colors: [colors.deepPurple, colors.pink200, colors.orange],
    shape: shapes.wave,
  }),
  tool: genPageTheme({
    colors: [colors.deepPurple, colors.pink200, colors.orange],
    shape: shapes.round,
  }),
};
