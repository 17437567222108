import { ItemCardHeader } from '@backstage/core-components';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { styled } from '@mui/styles';

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    marginRight: theme.spacing(2),
  },
  width: '100%',
}));

const SupportCard = styled(Card)(({theme}) => ({
  flex: 1,
  margin: theme.spacing(1),
  minWidth: 430,
  maxWidth: 430,
  transition: '0.3s',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: '0 6px 10px 3px rgba(0, 0, 0, .3)',
  },
}));

export const SupportRequests = () => {
  const [shouldExpand, setShouldExpand] = useState(true);

  const handleChange = (panel: any) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setShouldExpand(isExpanded ? panel : false);
  };
  return (
    <div style={{ width: '100%' }}>
      <Accordion
        style={{ width: '100%' }}
        expanded={shouldExpand}
        onChange={handleChange(!shouldExpand)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccessibilityNewIcon style={{ marginRight: 2 }} />
          <Typography>Support and Access Requests</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ overflow: 'auto' }}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SupportCard>
              <CardMedia>
                <ItemCardHeader title="Request Support" subtitle="Engage with the Platform Team" />
              </CardMedia>
              <CardContent>
                <div>
                  Need help?{' '}
                  <u>
                    <a
                      // editorconfig-checker-disable
                      href="https://dev.azure.com/nintex/Nintex/_wiki/wikis/Nintex.wiki/8454/Engaging-the-Platform-and-Infrastructure-team"
                      // editorconfig-checker-enable
                      target="_new"
                    >
                      Engage the Platform Team
                    </a>
                  </u>{' '}
                  by raising a work request.
                </div>
                <br />
                <div>
                  For K2 DevOps, click{' '}
                  <u>
                    <a
                      // editorconfig-checker-disable
                      href="https://tfs.k2.com/tfs/Labs/K2%202014/_workitems/create/Requirement?templateId=a3af1ccf-0f39-468d-a56a-4e06dd507979"
                      // editorconfig-checker-disable
                      target="_new"
                    >
                      here.
                    </a>
                  </u>{' '}
                </div>
              </CardContent>
              <CardActions>
                <a
                  href="https://dev.azure.com/nintex/Nintex/_workitems/create/Support?templateId=a9457568-1854-4fbc-8403-714cc7600614&ownerId=827421dc-93c6-4121-8a53-6a7fad6a5197"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button color="primary">Raise work request</Button>
                </a>
              </CardActions>
            </SupportCard>

            <SupportCard>
              <CardMedia>
                <ItemCardHeader title="Engineer Onboarding" subtitle="Welcome to Nintex!" />
              </CardMedia>
              <CardContent>
                <div>
                  To onboard a new team member check out the information about the{' '}
                  <u>
                    <a
                      href="https://nintex.sharepoint.com/sites/hr/SitePages/Human%20Resources%20and%20Talent%20Acquisition%20Wikis.aspx"
                      target="_new"
                    >
                      HR onboarding process
                    </a>
                  </u>
                  , and{' '}
                  <u>
                    <a
                      href="https://dev.azure.com/nintex//Nintex/_wiki/wikis/Nintex.wiki/8226/Access-Requests"
                      target="_new"
                    >
                      how to request access to the R+D systems.
                    </a>
                  </u>
                </div>
              </CardContent>
              <CardActions>
                <a
                  href="https://nintex-prodops.workflowcloud.com/forms/f8d5a120-736b-4b16-ba13-91ee85a53fa1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="text">Raise Access Request</Button>
                </a>
              </CardActions>
            </SupportCard>

            <SupportCard>
              <CardMedia>
                <ItemCardHeader title="Relevant Slack Channels" subtitle="Ask on Slack!" />
              </CardMedia>
              <CardContent>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>NSP (Nintex Shared Platform)</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C03M0K474PR"
                  >
                    #rnd_platform_nsp
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>NDP (Nintex Developer Portal)</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C03DKPU4DPS"
                  >
                    #rnd_platform_ndp
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>TechRadar</span>
                  <Link
                    style={{ minWidth: '150px' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C048A445LN6"
                  >
                    #rnd_tech-radar
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>ITDs (Important Technical Decisions)</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C02M3NTQ8F5"
                  >
                    #rnd_itds
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>GitHub Help</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C03SYL4LG1G"
                  >
                    #github_help
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>ADO Help</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C02M6LZQVF1"
                  >
                    #help_ado
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>API Standards Help</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/C01NDGFT7TP"
                  >
                    #help_api-standards
                  </Link>
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ marginRight: '8px' }}>DevOps Practice</span>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nintex.slack.com/archives/CJSLSVCSX"
                  >
                    #rnd_devops_practice
                  </Link>
                </div>
              </CardContent>
              <CardActions />
            </SupportCard>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
