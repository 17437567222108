import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const onboardingTrackerPlugin = createPlugin({
  id: 'onboarding-tracker',
  routes: {
    root: rootRouteRef,
  },
});

export const OnboardingTrackerPage = onboardingTrackerPlugin.provide(
  createRoutableExtension({
    name: 'OnboardingTrackerPage',
    component: () =>
      import('./components/OnboardingTracker').then(m => m.OnboardingTracker),
    mountPoint: rootRouteRef,
  }),
);
