import { TechRadarApi } from '@backstage-community/plugin-tech-radar';
import {
  TechRadarLoaderResponse,
  RadarRing,
  RadarQuadrant,
  RadarEntry,
} from '@backstage-community/plugin-tech-radar-common';
import { GitHubRepoApi } from '@internal/backstage-plugin-frontend-common-react';

const gitRepoUrl = 'https://github.com/NintexGlobal/platform-reference';
const branch = 'main';
const ringFilePath = 'ndp/components/techradar/rings.json';
const radarFilePath = 'ndp/components/techradar/radar.json';
const quadrantFilePath = 'ndp/components/techradar/quadrants.json';

export class TechRadarClient implements TechRadarApi {
  private githubRepoApi: GitHubRepoApi;

  constructor(githubRepoApi: GitHubRepoApi) {
    this.githubRepoApi = githubRepoApi;
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const rings = await this.githubRepoApi.fetchContent<RadarRing[]>(
      gitRepoUrl,
      branch,
      ringFilePath,
    );

    const entries = await this.githubRepoApi.fetchContent<RadarEntry[]>(
      gitRepoUrl,
      branch,
      radarFilePath,
    );

    const quadrants = await this.githubRepoApi.fetchContent<RadarQuadrant[]>(
      gitRepoUrl,
      branch,
      quadrantFilePath,
    );

    return {
      entries: entries.map(entry => ({
        ...entry,
        timeline: entry.timeline.map(t => ({
          ...t,
          date: new Date(t.date),
        })),
      })),
      rings,
      quadrants,
    };
  }
}
