import MyCustomLogoFullColor from './nintex-logo-color.svg';
import MyCustomLogoFullWhite from './nintex-logo-white.svg';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const LogoFull = () => {
  const theme = useTheme();

  return (
    <Box sx={{ marginLeft: 4 }}>
      <img
        src={theme.palette.mode === 'dark' ? MyCustomLogoFullWhite : MyCustomLogoFullColor}
        alt="Nintex Logo"
      />
    </Box>
  );
};

export default LogoFull;
