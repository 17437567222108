import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';
import { styled } from '@mui/styles';

const Tab = styled(MuiTab)(({ theme }) => ({
  padding: theme.spacing(1),
}));

interface IconValue {
  iconUrl: string;
  label: string;
  url: string;
}

interface TabData {
  tab: string;
  values: IconValue[];
}

export type TabAccordianProps = {
  item: {
    isExpanded?: boolean;
    title: string;
    links: {};
  };
  tab: any;
  onChange: (_: any, value: number) => void;
};

interface TabbedIconsProps {
  data: TabData[];
  tab: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

export const TabbedIcons: React.FC<TabbedIconsProps> = ({ data, tab, onChange }) => {
  const selectedTab = data[tab];

  return (
    <>
      <Tabs value={tab} onChange={onChange}>
        {data?.map((item, index) => (
          <Tab label={item.tab} key={index} />
        ))}
      </Tabs>
      <Grid container direction="row" alignItems="center" spacing={1}>
        {selectedTab?.values?.map((value, vIndex) => (
          <Grid item key={vIndex} mt={3}>
            <Link
              href={value?.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                '&:hover': {
                  textDecoration: 'underline',
                  '& $textUnderline': {
                    textDecoration: 'underline',
                  },
                },
                overflow: 'hidden', // Ensures that the circular icon doesn't spill over.
              }}
            >
              <Grid display="flex" flexDirection="column" alignItems="center" mr={2}>
                <Box
                  sx={{
                    height: '64px',
                    width: '64px',
                    padding: 1,
                    borderRadius: '50%',
                    backgroundColor: '#333333',
                    marginBottom: 0.5,
                    boxShadow:
                      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={value?.iconUrl}
                    alt={value?.label}
                    style={{
                      width: '90%',
                      height: '90%',
                    }}
                  />
                </Box>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{
                    textDecoration: 'none',
                    marginTop: 0.5,
                  }}
                >
                  {value?.label}
                </Typography>
              </Grid>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
