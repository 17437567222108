import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabbedIcons, TabAccordianProps } from './TabbedIcons';
import { styled } from '@mui/styles';

export const TabAccordian: React.FC<TabAccordianProps> = ({ item, tab, onChange }) => {
  const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    [`& .${accordionSummaryClasses.content}`]: {
      marginLeft: theme.spacing(2),
    },
    [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
      marginRight: theme.spacing(2),
    },
    width: '100%',
  }));

  const [shouldExpand, setShouldExpand] = useState(item.isExpanded);

  const handleChange = (panel: any) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setShouldExpand(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={shouldExpand} onChange={handleChange(!shouldExpand)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography marginLeft={2}>{item.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TabbedIcons data={item.links as any} tab={tab} onChange={onChange} />
      </AccordionDetails>
    </Accordion>
  );
};
