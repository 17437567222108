import { ScmIntegrationsApi, scmIntegrationsApiRef, ScmAuth } from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  analyticsApiRef,
  identityApiRef,
  fetchApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { TechRadarClient } from './TechRadarClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { githubRepoApiRef } from '@internal/backstage-plugin-frontend-common-react';
import { AggregateAnalyticsClient } from './AggregateAnalyticsClient';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),

  createApiFactory({
    api: analyticsApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      fetchApi: fetchApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ configApi, identityApi, fetchApi, discoveryApi }) =>
      AggregateAnalyticsClient.fromConfig(configApi, identityApi, fetchApi, discoveryApi),
  }),

  createApiFactory({
    api: techRadarApiRef,
    deps: {
      githubRepoApi: githubRepoApiRef,
    },
    factory: ({ githubRepoApi }) => new TechRadarClient(githubRepoApi),
  }),
];
