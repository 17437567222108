import { UnifiedThemeOptions } from '@backstage/theme';
import { headerFontFamily, colors } from './consts';

export const components: UnifiedThemeOptions['components'] = {
  MuiButton: {
    styleOverrides: {
      contained: {
        backgroundColor: colors.pink200,
        color: colors.ivory,
        '&:hover': {
          backgroundColor: colors.pink300,
        },
      },
      text: ({ theme }) => ({
        color: theme.palette.mode === 'dark' ? colors.ivory : colors.pink200,
        '&:hover': {
          color: theme.palette.mode === 'dark' ? colors.ivory : colors.pink200,
        },
      }),
      outlined: ({ theme }) => ({
        color: theme.palette.mode === 'dark' ? colors.ivory : colors.pink200,
        borderColor: colors.pink200,
      }),
    },
  },

  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.mode === 'dark' ? colors.ivory : colors.deepNavy,
        '&:hover': {
          color: theme.palette.mode === 'dark' ? colors.pink100 : colors.pink200,
        },
      }),
    },
  },

  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.mode === 'dark' ? colors.ivory : colors.pink200,
        '&.Mui-selected': {
          color: theme.palette.mode === 'dark' ? colors.ivory : colors.pink200,
          fontWeight: 'bold',
        },
        '&:hover': {
          color: theme.palette.mode === 'dark' ? colors.ivory : colors.pink200,
          fontWeight: 'bold',
        },
      }),
    },
  },

  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: colors.pink200,
      },
    },
  },

  MuiTypography: {
    styleOverrides: {
      h1: {
        fontFamily: headerFontFamily,
      },
      h2: {
        fontFamily: headerFontFamily,
      },
      h3: {
        fontFamily: headerFontFamily,
      },
      h4: {
        fontFamily: headerFontFamily,
      },
      h5: {
        fontFamily: headerFontFamily,
      },
    },
  },
};
