import {
  AnalyticsApi,
  AnalyticsEvent,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

export class PlatformAnalyticsClient implements AnalyticsApi {
  static fromConfig(
    identityApi: IdentityApi,
    fetchApi: FetchApi,
    discoveryApi: DiscoveryApi,
  ): PlatformAnalyticsClient {
    return new PlatformAnalyticsClient(identityApi, fetchApi, discoveryApi);
  }

  userId: string | undefined;
  fetchApi: FetchApi;
  discoveryApi: DiscoveryApi;

  private constructor(identityApi: IdentityApi, fetchApi: FetchApi, discoveryApi: DiscoveryApi) {
    identityApi.getBackstageIdentity().then(identity => {
      this.userId = identity.userEntityRef;
    });

    this.fetchApi = fetchApi;
    this.discoveryApi = discoveryApi;
  }

  async captureEvent(event: AnalyticsEvent) {
    // captures any analytic events emitted from backstage (or any other consumers of the analytics API) and forwards
    // them to our platform analytics backend service for classification and storage
    const { action, ...rest } = event;

    const baseUrl = await this.discoveryApi.getBaseUrl('platform-analytics');
    this.fetchApi.fetch(`${baseUrl}/metrics`, {
      method: 'POST',
      body: JSON.stringify({
        action,
        subject: rest.subject,
        userId: this.userId ?? 'unknown',
        attributes: rest.attributes,
        context: rest.context,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
