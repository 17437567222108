import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@mui/material/Input';
import {
  automatonPlugin,
  createAutomatonFormFieldExtension,
  FormFieldComponent,
} from '@internal/backstage-plugin-automaton';
import { SyntheticEvent, useState } from 'react';
import { Product } from '@internal/backstage-plugin-config-cat-admin-common';
import { useConfigCatAdmin } from '../../useConfigCatAdmin';
import { useAsync } from 'react-use';

const ConfigCatProductField: FormFieldComponent = ({ id }) => {
  const { listProducts } = useConfigCatAdmin();
  const [value, setValue] = useState<string>();

  const { value: products } = useAsync(async () => {
    return await listProducts();
  });

  const onProductChange = (_event: SyntheticEvent, value: Product | null) => {
    if (!value) {
      setValue(undefined);
      return;
    }
    setValue(value.productId);
  };

  return (
    <>
      <Autocomplete
        sx={{ marginBottom: '1rem' }}
        options={products || []}
        getOptionLabel={option => `${option.name} (${option.productId})`}
        onChange={onProductChange}
        renderInput={params => <TextField {...params} label="Select a product" />}
      />
      <Input type="hidden" name={id} value={value} />
    </>
  );
};

export const ConfigCatProductFieldExtension = automatonPlugin.provide(
  createAutomatonFormFieldExtension({
    name: 'config-cat-product',
    component: ConfigCatProductField,
  }),
);
