import { InfoCard } from '@backstage/core-components';
import { ComponentAccordion, HomePageToolkit } from '@backstage/plugin-home';
import { useState } from 'react';
import { ErrorReport } from '../../common';
import Skeleton from '@mui/material/Skeleton';
import { QuickAccessLinks } from '../../types/types';
import { useApi, discoveryApiRef, fetchApiRef, errorApiRef } from '@backstage/core-plugin-api';
import { useFetchJsonFromGHRepo } from '@internal/backstage-plugin-frontend-common-react';
import Alert from '@mui/material/Alert';
import { TabAccordian } from './TabAccordian';
import { useAsync } from 'react-use';
import { Box, Container } from '@mui/material';

const QUICK_ACCESS_API_URL = '/quickaccesslinks';

export const QuickLinkLoader = () => {
  return (
    <Container>
      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={150} />
      <Container
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          paddingLeft: 20,
          width: '100%',
        }}
      >
        {[...Array(5)].map((_, index) => (
          <Box key={index} style={{ maxWidth: '70px', padding: '15px' }}>
            <Skeleton variant="circular" width={60} height={60} />
            <Skeleton variant="text" width={60} sx={{ fontSize: '1rem', offset: '10px' }} />
          </Box>
        ))}
      </Container>
      <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
    </Container>
  );
};

export const QuickAccess = () => {
  const [tab, setTab] = useState(0);
  const [isError, setIsError] = useState(false);

  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const errorApi = useApi(errorApiRef);
  const fetchJsonContent = useFetchJsonFromGHRepo();

  const { value: configData, error: configError } = useAsync(async () => {
    const url = await discoveryApi.getBaseUrl('platform');
    const response = await fetchApi.fetch(url + QUICK_ACCESS_API_URL);
    return response.json();
  });

  const { value: githubData } = useAsync(async () => {
    try {
      if (!configData) {
        return [];
      }

      const data = await fetchJsonContent<QuickAccessLinks[]>(
        configData.gitRepoUrl,
        configData.branch,
        configData.filePath,
      );

      return data;
    } catch (error) {
      errorApi.post({
        ...(error as Error),
        message: 'Error fetching glossary config data',
      });
      setIsError(true);
      return [];
    }
  }, [configData]);

  if (configError) {
    return <Alert severity="error">Could not fetch config: {configError.message}</Alert>;
  }

  const onChange = (_: any, value: number) => {
    setTab(value);
  };

  if (isError) {
    return <ErrorReport title="Could not fetch data." errorText="Unknown error" />;
  }

  return (
    <InfoCard title="Quick Access" noPadding>
      {githubData?.length === 0 ? (
        <QuickLinkLoader />
      ) : (
        githubData?.map(item => {
          if (!item) {
            errorApi.post(new Error('Encountered a null or undefined item in data array'));
            return <></>;
          }

          if (!item.isTabbed) {
            return (
              <HomePageToolkit
                key={item.title}
                title={item.title.toUpperCase()}
                tools={item.links[0].values.map(link => ({
                  ...link,
                  icon: <img height="40px" width="auto" src={link.iconUrl} alt={link.label} />,
                }))}
                Renderer={
                  item.isExpanded
                    ? props => <ComponentAccordion expanded {...props} />
                    : props => <ComponentAccordion {...props} />
                }
              />
            );
          }
          return <TabAccordian key={item.title} item={item} tab={tab} onChange={onChange} />;
        })
      )}
    </InfoCard>
  );
};
